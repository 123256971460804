// @flow strict
import React from 'react';
import moment from 'moment';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges
};

const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    {edges.map((edge, idx) => (
      idx === 0 ? (
        <div className={styles['feed__first']} key={edge.node.fields.slug}>
          <Link className={styles['feed__first-frame']} to={edge.node.fields.slug}>
            <Img className={styles['feed__first-frame-thumbnail']} fluid={edge.node.frontmatter.img.childImageSharp.fluid} />
          </Link>
          <div className={styles['feed__first-text']}>
            <h2 className={styles['feed__first-text-title']}>
              <Link className={styles['feed__first-text-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
            </h2>
            <Link className={styles['feed__first-text-description']} to={edge.node.fields.slug}>{edge.node.frontmatter.description}</Link>
            <div>
              <div className={'feed__first-text-metadata'}>
                <time className={styles['feed__first-text-metadata-time']} dateTime={moment(edge.node.frontmatter.date)}>
                  {moment(edge.node.frontmatter.date).format('YYYY MM DD')}
                </time>
                <br/>
                <span className={styles['feed__first-text-metadata-readtime']}>
                  {edge.node.fields.readingTime.text}
                </span>
                <br/>
                <Link to={edge.node.fields.categorySlug} className={styles['feed__first-text-metadata-category']}>
                  {edge.node.frontmatter.category}
                </Link>
                <br/>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles['feed__item']} key={edge.node.fields.slug}>
          <Link className={styles['feed__item-frame']} to={edge.node.fields.slug}>
            <Img className={styles['feed__item-frame-thumbnail']} fluid={edge.node.frontmatter.img.childImageSharp.fluid} />
          </Link>
          <div className={'feed__item-text'}>
            <Link to={edge.node.fields.categorySlug} className={styles['feed__item-metadata-category']}>
              {edge.node.frontmatter.category}
            </Link>
            <h2 className={styles['feed__item-text-title']}>
              <Link className={styles['feed__item-text-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
            </h2>
            <Link className={styles['feed__item-text-description']} to={edge.node.fields.slug}>{edge.node.frontmatter.description}</Link>
            <br/>
          </div>
          <div className={'feed__item-metadata'}>
            <time className={styles['feed__item-metadata-time']} dateTime={moment(edge.node.frontmatter.date)}>
              {moment(edge.node.frontmatter.date).format('YYYY MM DD')}
            </time>
            <br/>
            <span className={styles['feed__item-metadata-readtime']}>
              {edge.node.fields.readingTime.text}
            </span>
            <br/>
          </div>
        </div>
      )
      
    ))}
  </div>
);

export default Feed;
